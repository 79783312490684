
@font-face {
  font-family: 'Roboto Thin';
  src: local('Roboto Thin'), url(./fonts/Roboto/roboto-v29-latin-300.woff) format('woff');
}

@font-face {
  font-family: 'Roboto Light';
  src: local('Roboto Light'), url(./fonts/Roboto/roboto-v29-latin-100.woff) format('woff');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/roboto-v29-latin-regular.woff) format('woff');
}

body{
  background-color: #fafafa;
  padding: 0;
  margin: 0;
}

.body-wrapper .container-fluid{
  padding:0;
  margin:0;
}
.navbar{
  background-color: #078dfc;
  height: 60px;
}

.navbar-header a{
  font-family: 'Roboto Thin', sans-serif;
  color: #FFFFFF;
  font-size: 30px;
  text-decoration: none;
}

.navbar-nav a{
  font-family: 'Roboto Thin', sans-serif;
  color: #FFFFFF;
  font-size: 20px;
  text-decoration: none;
}

.navbar-nav a:hover{
 text-decoration: underline;
 cursor: pointer;
}
