
.posts{
    margin-top: 3rem;
    font-family: 'Roboto Thin';
    margin-left: auto;
    margin-right: auto;
    border: 0.5px solid rgba(0, 0, 0, 0.007);
    box-shadow: 1px 0px 7px 7px #cfcece3b;
}
.posts div{
    margin-top: 5px;
    margin-bottom: 5px;
}
.posts .title{
    position: relative;
    margin-top: 10px;
    font-size: 25px;
}



.posts .content-with-url p{
    display: inline;
}

.tint{
    background-color: #fcb34721;
}

.non-tint{
    background-color: #FFF;
}
.formatted-date,.author-name{
    font-style: italic;
    font-weight: normal;
}

.author,.date{
    font-weight: bold;
    font-size: 0.9em;
}
