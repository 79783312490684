
.form-control {
    border-radius: 0;
}

.form-group{
    font-family: 'Roboto Thin';
    margin-top: 15px;
}
.inactive{
    display: none;
}

.active{
    display: block;
    color:#bd2d2d;
}
.success-active{
    display: block;
    color:rgb(21, 95, 3);
}

.content-text{
    text-align: justify;
    
}

.content-with-url{
    text-align: justify;
    
}

.success-inactive{
    display: none;
}
.btn{
    margin-top: 20px;
    color: #FFFFFF;
    background-color: rgb(0, 162, 255);
}

